<template>
  <div class="autoLodContent">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import _ from "@/tools/lodash";

export default {
  name: "AutoLogin",
  data() {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * When User trying to connect directly from Client
     * Use the Sirion token in URL
     * If exist convert it to Smaug token
     * and auto log
     * @returns {Promise<void>}
     */
    async checkIfAutoLogProfileTokenExist(){
      this.loading = true
      try {
        await this.axios.post('/user/auto_login', {
          auth_token: !_.isUndefined(this.$route.params.token) ? this.$route.params.token : await this.$router.push({name: 'Login'})
        }).then((response) => {
          if (response.status === 200)
          {
            this.$session.set('token', response.data.auth_token)
            this.$session.set('key', response.data.user_key)


              this.$router.push({name: 'User'})

          }
        })
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid values.', '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid method.', '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Unknown error occurred.', '4000')
          }
        }
      } finally {
        this.loading = false
      }
    },
    async checkIfAutoLogRoomTokenExist(){
      this.loading = true
      try {
        await this.axios.post('/user/auto_login', {
          auth_token: !_.isUndefined(this.$route.params.token) ? this.$route.params.token : await this.$router.push({name: 'Login'})
        }).then((response) => {
          if (response.status === 200)
          {
            this.$session.set('token', response.data.auth_token)
            this.$session.set('key', response.data.user_key)

            this.$router.push({
              name: 'GeneralRoomEdit', params: {room_key: this.$route.params.name}})

          }
        })
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {

              //Invalid parameters
            case 400:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid values.', '4000')
              break

              //Invalid token
            case 401:
              this.$session.destroy()
              await this.$router.push({name: 'Login'})
              break;

              // Method not allowed
            case 405:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Invalid method.', '4000')
              break

            default:
              this.snackbar('error', 'mdi-alert-circle-outline', 'Unknown error occurred.', '4000')
          }
        }
      } finally {
        this.loading = false
      }
    }
  },
  async mounted(){

      this.loading = true
    if (this.$route.name === 'AutoLoginProfile')
      await this.checkIfAutoLogProfileTokenExist()

    if (this.$route.name === 'AutoLoginRoom')
      await this.checkIfAutoLogRoomTokenExist()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-overlay__scrim {
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
  }
}

.autoLodContent{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
